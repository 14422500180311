import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import Header from '../components/Header'
import MainWrapper from '../containers/MainWrapper'
import SEO from '../components/SEO'

const Post = styled.div`
  p {
    color: ${props => props.theme.colors.grey};
    text-align: center;
    font-family: 'Source Sans Pro','Roboto','Helvetica','Arial',sans-serif;
    font-size: 20px;
    a {
      font-size: 20px;
      font-family: 'Source Sans Pro','Roboto','Helvetica','Arial',sans-serif;
    }
  }
`

const Booking = ({ location, data: { booking } }) => {
  return (
    <React.Fragment>
      <SEO 
        title={`Alexx & Mooonshiners | Contact/Booking`}
        desc={`Booking, Management/Promotion, Label`}
        pathname={location.pathname}
      />
      <Header location={location} />
      <MainWrapper style={{ margin: '0 auto'}}>
        <Post dangerouslySetInnerHTML={{ __html: booking.data.texte.html }} />
      </MainWrapper>
    </React.Fragment>
  )
}

export default Booking;
export const pageQuery = graphql`
  query BookingPage {
    booking: prismicBooking {
      data {
        texte {
          html
        }
      }
    }
  }
`